import gql from "graphql-tag";

export default class AccountQuery {
  static signup() {
    return gql`
      mutation CreatorCreate($email: String!, $password: String!) {
        CreatorCreate(email: $email, password: $password) {
          message
          status
        }
      }
    `;
  }

  static login() {
    return gql`
      mutation CreatorLogin($email: String!, $password: String!) {
        CreatorLogin(email: $email, password: $password) {
          status
          authorization {
            token
            type
            expires_in
          }
        }
      }
    `;
  }

  static getInfo() {
    return gql`
      query CreatorGetOwnData {
        CreatorGetOwnData {
          id
          first_name
          last_name
          email
          onboard_position
          role
          has_verified_email
          gender {
            id
            name
          }
          categories {
            name
            id
          }
        }
      }
    `;
  }

  static onBoardStep1() {
    return gql`
      mutation CreatorOnboardPositionOne(
        $firstName: String!
        $lastName: String!
        $category_id_1: ID!
        $category_id_2: ID!
        $category_id_3: ID!
      ) {
        CreatorOnboardPositionOne(
          first_name: $firstName
          last_name: $lastName
          category_id_1: $category_id_1
          category_id_2: $category_id_2
          category_id_3: $category_id_3
        ) {
          status
          message
        }
      }
    `;
  }

  static resendVerificationCode() {
    return gql`
      mutation CreatorResendEmailVerificationCode {
        CreatorResendEmailVerificationCode {
          status
          message
        }
      }
    `;
  }

  static verificationCode() {
    return gql`
      mutation CreatorEmailVerificationByCode($code: String!) {
        CreatorEmailVerificationByCode(email_validation_code: $code) {
          status
          message
        }
      }
    `;
  }
}
