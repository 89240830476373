import type { IntlDateTimeFormat, IntlDateTimeFormats, IntlNumberFormats } from "vue-i18n";
import { createI18n } from "vue-i18n";

import kzEn from "@keyfluenz/vue-core/dist/locales/en.json";
import kzEs from "@keyfluenz/vue-core/dist/locales/es.json";

import en from "@/locales/en.json";
import es from "@/locales/es.json";

const setDateTimeFormats: IntlDateTimeFormat = {
  short: {
    year: "numeric",
    month: "short",
    day: "numeric",
  },
  time: {
    hour: "numeric",
    minute: "numeric",
  },
  long: {
    year: "numeric",
    month: "long",
    day: "numeric",
    weekday: "long",
    hour: "numeric",
    minute: "numeric",
  },
};

const datetimeFormats: IntlDateTimeFormats = {
  en: setDateTimeFormats,
};

const numberFormats: IntlNumberFormats = {
  en: {
    currency: {
      style: "currency",
      currency: "USD",
    },
    decimal: {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
    percent: {
      style: "percent",
      useGrouping: false,
    },
  },
};

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: import.meta.env.VITE_APP_I18N_LOCALE || "en",
  fallbackLocale: import.meta.env.VITE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: {
    en: {
      ...kzEn,
      ...en,
    },
    es: {
      ...kzEs,
      ...es,
    },
  },
  datetimeFormats,
  numberFormats,
});
