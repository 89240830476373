<script lang="ts" setup>
import { BookOpenIcon, HomeIcon, SparklesIcon, ViewColumnsIcon } from "@heroicons/vue/24/outline";
import KFBlueIcon from "@/components/icons/KFBlueIcon.vue";
import KeyFluenzIcon from "@/components/icons/KeyFluenzIcon.vue";
import { KzAvatar, KzFlyout } from "@keyfluenz/vue-core";
import { useRouter } from "vue-router";
import AuthService from "@/helpers/AuthService";
import { computed, onMounted, ref } from "vue";
import useAccountHandler from "@/helpers/useAccountHandler";
import NavigationElement from "../components/NavigationElement.vue";
import { useI18n } from "vue-i18n";
import type { UserType } from "@/types/AccountTypes";

interface NavigationElementDetail {
  name: string;
  to: string;
  icon: unknown;
  current: boolean;
  type: string;
}

defineOptions({
  name: "NavigationSidebar",
});

const { accountInfo, getInfo } = useAccountHandler();

const { t } = useI18n();
const router = useRouter();
const collapsed = ref(false);

const solutions = [{ name: "Log Out" }];
const navigation = [
  { name: "Demo", to: "Demo", icon: BookOpenIcon, current: false, type: "button" },
  { name: "Dashboard", to: "Dashboard", icon: HomeIcon, current: true, type: "link" },
  {
    name: t("common.sidebar.deals"),
    to: "DealsDiscovery",
    icon: HomeIcon,
    current: true,
    type: "link",
  },
] as NavigationElementDetail[];

const collapseElement = {
  name: "Collapse sidebar",
  to: "toggle",
  icon: ViewColumnsIcon,
  current: false,
  type: "button",
} as NavigationElementDetail;

const test = ref({} as UserType);

const nameInfo = computed((): string => {
  return `${accountInfo.value?.first_name} ${accountInfo.value?.last_name}` || "Admin";
});

const emailInfo = computed((): string => {
  return accountInfo.value?.email || "";
});

onMounted(async () => {
  if (accountInfo.value === null) {
    test.value = await getInfo(true);
  }
});

function logOut() {
  AuthService.deleteToken();
  router.push({ name: "Login" });
}

function toggle() {
  collapsed.value = !collapsed.value;
}

function onAction(action: string) {
  if (action === "toggle") {
    toggle();
  }
}
</script>

<template>
  <aside :class="['navigation-sidebar', { collapsed: collapsed }]">
    <div v-if="!collapsed" class="navigation-sidebar-logo">
      <KeyFluenzIcon class="w-48" />
    </div>
    <div v-else class="navigation-sidebar-logo">
      <KFBlueIcon class="w-7 my-[19px]" />
    </div>
    <nav class="navigation-sidebar-menu">
      <ul class="flex flex-1 flex-col gap-y-4" role="list">
        <li v-for="item in navigation" :key="item.name">
          <NavigationElement :collapsed="collapsed" :element="item" />
        </li>
        <li class="mt-auto mb-6">
          <NavigationElement :collapsed="collapsed" :element="collapseElement" @action="onAction" />
        </li>
      </ul>
    </nav>
    <div class="divider"></div>
    <div class="navigation-sidebar-user">
      <div class="user-menu">
        <KzFlyout data-testid="flyout-avatar">
          <template #button>
            <div class="flex space-x-4 items-center max-w-52">
              <KzAvatar :name="nameInfo" class="flex-none w-8 h-8" />
              <div>
                <p v-if="!collapsed" class="user-menu-name">{{ nameInfo }}</p>
                <p v-if="!collapsed" class="user-menu-email">{{ emailInfo }}</p>
              </div>
            </div>
          </template>
          <template #items>
            <button
              v-for="item in solutions"
              :key="item.name"
              class="p-2 hover:text-blue-600"
              @click="logOut"
            >
              {{ item.name }}
            </button>
          </template>
        </KzFlyout>
      </div>
    </div>
  </aside>
</template>

<style lang="scss" scoped>
.navigation-sidebar {
  @apply top-0 left-0 flex flex-col w-[260px] bg-white border-r border-neutral-200 px-4 transition duration-700 ease-in-out;

  &-logo {
    @apply mx-auto py-12 transition duration-700 ease-in-out;
  }

  &-menu {
    @apply flex flex-1;
  }

  &.collapsed {
    @apply w-[108px] flex justify-center bg-jordy-700 items-center transition duration-700 ease-in-out;

    .navigation-sidebar-user {
      .user-menu {
        @apply m-2 hover:bg-white rounded-full h-11 w-11 flex items-center justify-center;
      }
    }

    :deep(.menu-element) {
      .navigation-icon {
        @apply mx-3 text-white hover:text-jordy-700 transition duration-700 ease-in-out;
      }

      &:hover {
        @apply bg-white;

        .navigation-icon {
          @apply text-jordy-700;
        }
      }

      &.current {
        @apply bg-white;

        .navigation-icon {
          @apply text-jordy-700;
        }
      }
    }

    :deep(.avatar) {
      @apply w-10 h-10 duration-700 ease-in-out;
    }

    .divider {
      @apply border-jordy-600;
    }
  }

  @media (max-width: 1024px) {
    position: absolute;
    z-index: 99;
  }

  &-user {
    @apply -mx-4 relative;

    .user-menu {
      @apply flex items-center gap-x-4 px-4 py-3;

      &-name {
        @apply text-start text-sm font-semibold text-jordy-700 hover:text-white break-all line-clamp-1;
      }

      &-email {
        @apply text-xs text-jordy-500 hover:text-white break-all line-clamp-1;
      }

      &:hover {
        @apply hover:bg-jordy-700;

        .user-menu-name,
        .user-menu-email {
          @apply text-white;
        }
      }
    }
  }

  .divider {
    @apply border-b border-jordy-300 w-full mb-6;
  }
}

:deep(.flyout__panel) {
  @apply translate-x-0;
}
</style>
