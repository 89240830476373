<script lang="ts" setup>
import { type PropType } from "vue";
import { useRoute } from "vue-router";

interface NavigationElementDetail {
  name: string;
  to: string;
  icon: unknown;
  current: boolean;
  type: string;
}

defineOptions({
  name: "NavigationElement",
});

const emit = defineEmits(["action"]);

const route = useRoute();

defineProps({
  element: {
    type: Object as PropType<NavigationElementDetail>,
    required: true,
  },
  collapsed: {
    type: Boolean,
    default: false,
  },
});

function onAction(action: string) {
  emit("action", action);
}
</script>

<template>
  <div class="navigation-element">
    <div v-if="element.type === 'link'">
      <router-link
        :to="{ name: element.to }"
        class="menu-element"
        :class="{ current: element.to === route.name }"
      >
        <component :is="element.icon" class="navigation-icon" />
        <span v-if="!collapsed" class="navigation-text">
          {{ element.name }}
        </span>
      </router-link>
    </div>
    <div v-if="element.type === 'button'">
      <button @click="onAction(element.to)" class="menu-element">
        <component :is="element.icon" class="navigation-icon" />
        <span v-if="!collapsed" class="navigation-text">
          {{ element.name }}
        </span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.navigation-element {
  .menu-element {
    @apply flex items-center w-full transition duration-500 ease-in-out py-2 px-3 rounded-full;

    .navigation-icon {
      @apply w-6 h-6 text-jordy-700 mr-4 transition duration-500 ease-in-out;
    }

    .navigation-text {
      @apply font-medium text-jordy-700 transition duration-500 ease-in-out;
    }

    &:hover {
      @apply bg-jordy-700 text-white;

      .navigation-icon,
      .navigation-text {
        @apply text-white;
      }
    }

    &.current {
      @apply bg-jordy-700;

      .navigation-icon,
      .navigation-text {
        @apply text-white;
      }
    }
  }
}
</style>
